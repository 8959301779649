.map_pilotage_toolbar {
  position: absolute;
  background-color: rgba(0,0,0,0.8);
  /*bottom: 100px;*/
  left: 0;
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  color: white;
}

/* header */
.map_pilotage_toolbar_header {
  padding: 20px 30px 15px 30px;
  /*padding-left: 20px;*/
  display: flex;
  justify-content: space-between;
}
.map_pilotage_toolbar_header .next_start {
  font-weight: bold;
}
.map_pilotage_toolbar_header .map_track_switch {
  font-weight: bold;
}

/* buttons */
.map_pilotage_toolbar_button_wrapper {
  padding: 10px 30px 10px 30px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn-wrapper {
  width: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn {
  background-color: var(--gold);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  cursor: pointer;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn.active {
  background-color: green;
  border: 2px solid white;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn.disable {
  background-color: black;
  border: 2px solid white;
  color: white;
  cursor: default;
}
.map_pilotage_toolbar_button_wrapper .tlb-btn span {
  font-size: 25px;
}
.map_pilotage_toolbar_button_wrapper span {
  padding-top: 5px;
  /* font-weight: bold; */
  text-transform: uppercase;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

/* map_pilotage_toolbar_footer */
.map_pilotage_toolbar_footer {
  padding: 10px 30px 10px 30px;
}
.map_pilotage_toolbar_footer .next_start span {
  text-transform: uppercase;
  color: var(--gold);
}

/* countdown */
.next_start .label {
  padding: 0;
}
.next_start_hidden {
  visibility: hidden;
}
.map_markers_drawer_btn {
  display: flex;
  position: fixed;
  bottom:0;
  right:23%;
}

#map_control {
  grid-template-columns: auto auto auto auto;
  grid-gap: 10px;
  /*padding: 10px;*/
  display: grid;
  /*height: 400px;*/
  /*grid-template-columns: 200px;*/
  /*grid-template-rows: repeat(6, 1fr);*/
}
#map_control > div {
  /*text-align: center;*/
  /*padding: 20px 0;*/
  font-size: 16px;
  /*grid-row: 1 ;*/
}
#map_control > div > label {
  margin-right: 20px;
}
/*.item1 {*/
/*  background-color: lime;*/
/*  grid-row: 1 / 4;*/
/*}*/

/*.item2 {*/
/*  background-color: yellow;*/
/*  grid-row: 1 / 4;*/
/*}*/

/*.item3 {*/
/*  background-color: blue;*/
/*  grid-row: 1 / 4;*/
/*}*/

#force-network-switch-container {
  float: right;
}

#force-network-switch {
    background-color: var(--gold);
}